


















































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsOnboardingHeader from '@/modules/onboarding/components/main/header.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';
import {Action, Getter} from 'vuex-class';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import {ONBOARDING_STATE} from '@/shared/constants/ONBOARDING_STATE';
import {DashboardRouter} from '@/modules/dashboard/router';
import RsButton from '@/shared/components/buttons/button.vue';

@Component({
  metaInfo: {
    title: 'How Remotesome works'
  },
  components: {RsButton, RsIcon, RsOnboardingHeader}
})
export default class OnboardingHowItWorks extends Vue {
  @Action(OnboardingActions.LOGOUT, {namespace: OnboardingModule.namespace}) logout: () => void;
  @Action(OnboardingActions.UPDATE_USER, {namespace: OnboardingModule.namespace}) updateUser: (user: IApiTalentUpdateRequest) => Promise<IApiTalent>;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  Icons = Icons;

  sending = false;

  next(){
    this.sending = true;
    const data: IApiTalentUpdateRequest = {
      uiContext: {
        onboardingStatus: ONBOARDING_STATE.DONE
      },
    }
    this.updateUser(data)
      .then(() => {
        this.$router.push({name: DashboardRouter.INDEX})
      })
      .catch(() => {})
      .finally(() => {
        this.sending = false;
      })
  }
  mounted(){
    this.$track.sendEvent('OnboardingHowItWorks')
  }
}
